





















import { Component } from "vue-property-decorator";
import { Guid } from "guid-typescript";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { RoleModel } from '@/services/iIdentityService';
import { RolesModule } from './store';

import PgaRoleDetails from "./Components/PgaRoleDetails.vue";

@Component( {
	components: {
		PgaRoleDetails
	},
} )
export default class PgaRolesPageDetails extends mixins( SharedMixin )
{
	get RoleDetails (): RoleModel
	{
		return RolesModule.RoleDetails;
	}

	private Edit (): void
	{
		const id = this.$route.params.id;
		this.$router.push( { name: "roleedit", params: { id } } );
	}

	private SelectUsers (): void
	{
		const id = this.$route.params.id;
		this.$router.push( { name: "roleselectusers", params: { id } } );
	}

	async mounted (): Promise<void>
	{
		const id = this.$route.params.id;
		await RolesModule.GetRoleDetails( Guid.parse( id ) );
	}
}
