













import { Component } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { RolesModule, SelectUsersPerRoleModel } from './store';
import PgaRolesSelectUsers from './Components/PgaRolesSelectUsers.vue';

@Component({
    components:
    {
		PgaRolesSelectUsers,
	}
})

export default class RolesPageSelectUsers extends mixins(SharedMixin)
{

	get RolesSelectUsers() : SelectUsersPerRoleModel
	{
		return RolesModule.SelectUsers;
	}

	async SelectUsers() : Promise<void>
	{
		try
		{
			await RolesModule.SelectUsersPerRole();
			this.$router.go(-1);
		}
		// eslint-disable-next-line no-empty
		catch(error) { }
	}

	undo() : void 
	{
		this.$router.go(-1);
    }
}
