















import { Component } from "vue-property-decorator";
import { RolesModule, CreateRoleModel } from "./store";
import { CreateRoleResponse } from "@/services/iIdentityService";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaRoleCreate from "./Components/PgaRoleCreate.vue";

@Component({
  components: {
    PgaRoleCreate
  },
})
export default class PgaRolesPageCreate extends mixins(SharedMixin) {
  
  get RoleCreate(): CreateRoleModel
  {
    return RolesModule.RoleCreate;
  }
  
  async Create() : Promise<void>
  {
    try 
    {
      const response: CreateRoleResponse = await RolesModule.CreateRole();
      const id: string = response.Id.toString();
      this.$router.push({ name: 'roledetails', params: { id }});
    }
    // eslint-disable-next-line no-empty
    catch(error) { }
	}

  cancel(): void
  {
    this.$router.go(-1);
  }
  
  async mounted(): Promise<void>
  {
    RolesModule.InitRoleCreate();
    await RolesModule.GetPermissionsDropDown();
  }

  beforeDestroy() : void
	{
		RolesModule.DropRoleCreate();
		RolesModule.RoleCreate.Errors = new Map<string, Array<string>>();
	} 
}
