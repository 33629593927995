
















































import { Component, Prop } from "vue-property-decorator";
import { PermissionModel, RoleModel } from "@/services/iIdentityService";

import { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

@Component({})
export default class PgaRolesTableIndex extends mixins(SharedMixin)
{
  @Prop({ default: function() { return new Array<RoleModel>() } }) readonly RolesList!: Array<RoleModel>;
  @Prop({ default: true }) readonly isLoading!: boolean; 

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers: Array<any> = [
    {
      text: "Id",
      align: " d-none",
      sortable: true,
      value: "Id",
    },
    {
      text: "Name",
      align: "start",
      sortable: true,
      value: "Name",
    }
  ];  

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
  rowClicked(item: any): void {
    const id = item.Id;
    this.$router.push({ name: "roledetails", params: { id } });
  }

  private permissionsFiltered(permissions: Array<PermissionModel>, filter: string) : Array<PermissionModel>
  {
    return permissions?.filter(p => p.Code.includes(filter)) ?? new Array<PermissionModel>();
  }

  private allOtherPermissions(permissions: Array<PermissionModel>) : Array<PermissionModel>
  {
    const condition =  p => !p.Code.includes('Read') && !p.Code.includes('Edit') && !p.Code.includes('Delete') ;
    return permissions?.filter(p => condition(p)) ?? new Array<PermissionModel>();
  }
}
