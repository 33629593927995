


































import { Component, Model } from "vue-property-decorator";
import { RolesModule } from "../store";
  
import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

import { CreateRoleCommand, PermissionModel } from "@/services/iIdentityService";

@Component({
  components: { },
})
export default class PgaRoleCreate extends mixins(SharedMixin)
{
  @Model("", { type: Object }) readonly Model!: CreateRoleCommand;

  get name(): string
  {
    return this.Model?.Name ?? "";
  }
  set name(name: string)
  {
    RolesModule.SET_ROLE_CREATE_NAME(name);
  }

  get permissionsSelected() : Array<PermissionModel>
  {
    return new Array<PermissionModel>();
  }
  set permissionsSelected(value: Array<PermissionModel>) {
    RolesModule.SET_ROLE_CREATE_PERMISSIONS(value.map(p => p.Id));
  }

  get allPermissions(): Array<PermissionModel>
  {
    return RolesModule.PermissionsDropDown.Permissions;
  }

  get Errors(): Map<string, Array<string>> {
    return RolesModule.RoleCreate?.Errors ?? new Map<string, Array<string>>();
  }
}
