




































import { Component, Model } from "vue-property-decorator";
import { RolesModule } from "../store";

import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

import { EditRoleCommand, PermissionModel } from "@/services/iIdentityService";

@Component({
  components: { },
})
export default class PgaRoleEdit extends mixins(SharedMixin) {
  @Model("", { type: Object }) readonly Model!: EditRoleCommand;

  get Errors(): Map<string, Array<string>> {
    return RolesModule.RoleEdit?.Errors ?? new Map<string, Array<string>>();
  }

  get name(): string {
    return this.Model?.Name ?? "";
  }
  set name(name: string) {
    RolesModule.SET_ROLE_EDIT_NAME(name);
  }


  get permissionsSelected() : Array<PermissionModel>
  {
    return RolesModule.RoleEdit.ViewModel?.Permissions ?? new Array<PermissionModel>();
  }
  set permissionsSelected(value: Array<PermissionModel>)
  {
    RolesModule.SET_ROLE_EDIT_PERMISSIONS(value.map(e => e.Id));
  }
  
  get allPermissions(): Array<PermissionModel>
  {
    return RolesModule.PermissionsDropDown.Permissions;
  }
}
