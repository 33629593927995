import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '@/infrastructure/store'
import { Guid } from 'guid-typescript';

import IIdentityService from '@/services/iIdentityService';
import IdentityService from '@/services/IdentityService';
import pgaDiContainer from '@/App.container';

import { RoleModel, PermissionModel, ListRoles, ListPermissions,
        CreateRoleCommand, EditRoleCommand, DeleteRoleCommand, SelectUsersPerRoleCommand,
        CreateRoleResponse, EditRoleResponse, DeleteRoleResponse, SelectUsersPerRoleResponse } from '@/services/iIdentityService';
import { SharedModule } from '@/feature/Shared/store';

export interface RolesState
{
    RoleTable: ListRoles;
    RoleDetails: RoleModel | null;
    RoleCreate: CreateRoleModel;
    RoleEdit: EditRoleModel;
    RoleDelete: DeleteRoleModel;
    SelectUsers: SelectUsersPerRoleModel;

    PermissionsDropDown: ListPermissions;
}

export interface CreateRoleModel
{
    Command: CreateRoleCommand | null;
    Response: CreateRoleResponse | null;
    Errors: Map<string, Array<string>>;
}

export interface EditRoleModel
{
    ViewModel: RoleModel | null;
    Command: EditRoleCommand | null;
    Response: EditRoleResponse | null;
    Errors: Map<string, Array<string>>;
}

export interface DeleteRoleModel
{
    Id: Guid;
    Response: DeleteRoleResponse | null;
}

export interface SelectUsersPerRoleModel
{
    ViewModel: RoleModel | null;
    Command: SelectUsersPerRoleCommand | null;
    Response: SelectUsersPerRoleResponse | null;
}

@Module({ namespaced: true, dynamic: true, store, name: 'RolesModule', })
class RolesStore extends VuexModule implements RolesState
{
    _identityService: IIdentityService = pgaDiContainer.get<IIdentityService>(IdentityService);

    public RoleTable: ListRoles = 
    {
        Roles: new Array<RoleModel>(),
    };

    public RoleDetails: RoleModel = 
    { 
        Id: Guid.createEmpty(),
        Name: '',
        Permissions: new Array<PermissionModel>(),
    };

    public RoleCreate: CreateRoleModel = 
    {
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>(),
    };

    public RoleEdit: EditRoleModel = 
    {
        ViewModel: null,
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>(),
    };

    public RoleDelete: DeleteRoleModel =
    {
        Id: Guid.createEmpty(),
        Response: null,
    }

    public SelectUsers: SelectUsersPerRoleModel =
    {
        ViewModel: null,
        Command: null,
        Response: null,
    };
    

    public PermissionsDropDown: ListPermissions =
    {
        Permissions: new Array<PermissionModel>(),
    }

    // DropDown Permissions
    @Action({ rawError: true })
    public async GetPermissionsDropDown()
    {
        const permissions: ListPermissions = await this._identityService.ListPermissions();
        this.SET_PERMISSION_DROPDOWN(permissions);
    }
    @Mutation
    public SET_PERMISSION_DROPDOWN(permissions: ListPermissions)
    {
        this.PermissionsDropDown = permissions;
    }

    // List Roles
    @Action({ rawError: true })
    public async GetAllRoles(): Promise<void> 
    {
        const roles: ListRoles = await this._identityService.ListRoles();
        this.SET_ROLES(roles);
    }
    @Mutation
    public SET_ROLES(roles: ListRoles)
    {
        this.RoleTable = roles;
    }

    // Details Role
    @Action({ rawError: true })
    public async GetRoleDetails(id: Guid)
    {
        SharedModule.SET_ISLOADING(true);
        const role: RoleModel = await this._identityService.GetRole(id);
        this.SET_ROLE_DETAILS(role);
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public DropRoleDetails()
    {
        const resetRoleDetails: RoleModel =
        {
            Id: Guid.createEmpty(),
            Name: '',
            Permissions: new Array<PermissionModel>(),
        };
        this.SET_ROLE_DETAILS(resetRoleDetails);
    }
    @Mutation
    public SET_ROLE_DETAILS(role: RoleModel)
    {
        this.RoleDetails = role;
    }

    // Create Role
    @Action({ rawError: true })
    public async CreateRole()
    {
        if (!this.RoleCreate)
        {
            Promise.reject("Role creation not initialized");
        }
        
        try
        {
            let response: CreateRoleResponse = { Id: Guid.createEmpty(), };
            SharedModule.SET_ISLOADING(true);
            if (this.RoleCreate.Command)
                response = await this._identityService.CreateRole(this.RoleCreate.Command);
            SharedModule.SET_ISLOADING(true);
            return response;
        }
        catch (error)
        {            
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_ROLE_CREATE_ERRORS(errs);

            throw error;
        }
    }
    @Action({ rawError: true })
    public InitRoleCreate()
    {
        const command: CreateRoleCommand =
        {
            Name: '',
            Permissions: new Array<Guid>(),
        }

        this.SET_ROLE_CREATE(command);
    }
    @Action({ rawError: true })
    public async DropRoleCreate()
    {
        this.SET_ROLE_CREATE(null);
    }
    @Mutation
    public SET_ROLE_CREATE(command: CreateRoleCommand | null)
    {
        this.RoleCreate.Command = command;
    }
    @Mutation
    public SET_ROLE_CREATE_NAME(name: string)
    {
        if(this.RoleCreate.Command)
            this.RoleCreate.Command.Name = name;
    }
    @Mutation
    public SET_ROLE_CREATE_PERMISSIONS(permissions: Array<Guid>)
    {
        if(this.RoleCreate.Command)
            this.RoleCreate.Command.Permissions = permissions;
    }
    @Mutation
    public async SET_ROLE_CREATE_ERRORS(errors: Map<string, Array<string>>)
    {
        this.RoleCreate.Errors = errors;
    }
    
    // Edit Role    
    @Action({ rawError: true })
    public async EditRole() : Promise<EditRoleResponse>
    {
        try
        {
            let response: EditRoleResponse = { Id: Guid.createEmpty(), };
            SharedModule.SET_ISLOADING(true);
            if(this.RoleEdit.Command)
                response = await this._identityService.EditRole(this.RoleEdit.Command);
            SharedModule.SET_ISLOADING(false);

            return response;
        }
        catch (error)
        {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_ROLE_EDIT_ERRORS(errs);

            throw error;
        }
    }
    @Action({ rawError: true })
    public async GetRoleToEdit(id: Guid)
    {
        SharedModule.SET_ISLOADING(true);

        this.SET_ROLE_EDIT_COMMAND(null);
        this.SET_ROLE_EDIT_VIEWMODEL(null);

        const role: RoleModel = await this._identityService.GetRole(id);
        
        this.SET_ROLE_EDIT_VIEWMODEL(role);
        this.InitRoleEdit();

        this.SET_ROLE_EDIT_ID(role.Id);
        this.SET_ROLE_EDIT_NAME(role.Name);
        this.SET_ROLE_EDIT_PERMISSIONS(role.Permissions.map(p => p.Id));
        
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public InitRoleEdit()
    {
        const command: EditRoleCommand =
        {
            Id: Guid.createEmpty(),
            Name: '',
            Permissions: new Array<Guid>(),
        }

        this.SET_ROLE_EDIT_COMMAND(command);
    }
    @Mutation
    public SET_ROLE_EDIT_VIEWMODEL(role: RoleModel | null)
    {
        this.RoleEdit.ViewModel = role;
    }
    @Mutation
    public SET_ROLE_EDIT_COMMAND(command: EditRoleCommand | null)
    {
        this.RoleEdit.Command = command;
    }
    @Mutation
    public SET_ROLE_EDIT_ID(id: Guid)
    {
        if (this.RoleEdit.Command)
            this.RoleEdit.Command.Id = id;
    }
    @Mutation
    public SET_ROLE_EDIT_NAME(name: string)
    {
        if (this.RoleEdit.Command)
            this.RoleEdit.Command.Name = name;
    }
    @Mutation
    public SET_ROLE_EDIT_PERMISSIONS(permissions: Array<Guid>)
    {
        if (this.RoleEdit.Command)
            this.RoleEdit.Command.Permissions = permissions;
    }
    @Mutation
    public SET_ROLE_EDIT_ERRORS(errors: Map<string, Array<string>>)
    {
        this.RoleEdit.Errors = errors;
    }
    
    // Delete Role
    @Action({ rawError: true })
    public async DeleteRole(id: Guid): Promise<DeleteRoleResponse>
    {
        try {
            let response: DeleteRoleResponse = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING(true);
            this.SET_ROLE_DELETE(id);

            if (this.RoleDelete)
                response = await this._identityService.DeleteRole(this.RoleDelete.Id);
            
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);

            throw error;
        }
    }
    @Mutation
    public SET_ROLE_DELETE(id: Guid)
    {
        this.RoleDelete.Id = id;
    }

    // Select Users per Role    
    @Action({ rawError: true })
    public async SelectUsersPerRole() : Promise<SelectUsersPerRoleResponse>
    {
        try
        {
            let response: SelectUsersPerRoleResponse = { Id: Guid.createEmpty(), };
            SharedModule.SET_ISLOADING(true);
            if(this.SelectUsers.Command)
                response = await this._identityService.SelectUsersPerRole(this.SelectUsers.Command);
            SharedModule.SET_ISLOADING(false);

            return response;
        }
        catch (error)
        {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_ROLE_EDIT_ERRORS(errs);

            throw error;
        }
    }
    @Action({ rawError: true })
    public async GetRoleToSelectUser(id: Guid)
    {
        SharedModule.SET_ISLOADING(true);

        const role: RoleModel = await this._identityService.GetRole(id);
        
        this.SET_ROLE_SELECT_USERS_VIEWMODEL(role);
        this.InitSelectUsersPerRoleCommand();

        this.SET_ROLE_SELECT_USERS_COMMAND_ID(role.Id);
        const users = await this._identityService.GetAllUsersByRoleId(role.Id);
        this.SET_ROLE_SELECT_USERS_COMMAND_USERIDS(users.map(u => u.Id));
        
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public InitSelectUsersPerRoleCommand()
    {
        const command: SelectUsersPerRoleCommand =
        {
            Id: Guid.createEmpty(),
            UserIds: new Array<Guid>(),
        }
        this.SET_ROLE_SELECT_USERS_COMMAND(command);
    }
    @Mutation
    public SET_ROLE_SELECT_USERS_COMMAND(command: SelectUsersPerRoleCommand | null)
    {
        this.SelectUsers.Command = command;
    }
    @Mutation
    public SET_ROLE_SELECT_USERS_VIEWMODEL(view: RoleModel | null)
    {
        this.SelectUsers.ViewModel = view;
    }
    @Mutation
    public SET_ROLE_SELECT_USERS_COMMAND_ID(roleId: Guid)
    {
        if(this.SelectUsers.Command !== null)
            this.SelectUsers.Command.Id = roleId;
    }
    @Mutation
    public SET_ROLE_SELECT_USERS_COMMAND_USERIDS(userIds: Array<Guid>)
    {
        if(this.SelectUsers.Command !== null)
            this.SelectUsers.Command.UserIds = userIds;
    }
}

function parseErrors(error: any): Map<string, Array<string>> {
    const errs = new Map<string, Array<string>>();
    for (const [key, value] of Object.entries(error.response.data)) {
        const messages = value as Array<string>;
        errs.set(key, messages);
    }
    return errs;
}

export const RolesModule = getModule(RolesStore);
