





























import { Component, Model } from "vue-property-decorator";

import { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

import { PermissionModel, RoleModel } from "@/services/iIdentityService";

@Component({
  components: { },
})
export default class PgaRoleDetails extends mixins(SharedMixin)
{
  @Model("", { type: Object }) readonly Model!: RoleModel;

  get name(): string
  {
    return this.Model?.Name ?? ""; 
  }

  get permissions(): Array<PermissionModel>
  {
    return this.Model?.Permissions ?? new Array<PermissionModel>();
  }
}
