












import { Model } from 'vue-property-decorator';

import { Guid } from 'guid-typescript';
import Component, { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

import LookUpsModule from '@/feature/Shared/LookUp/store';
import { RolesModule } from '../store';
import { RoleModel, SelectUsersPerRoleCommand, UserModel } from '@/services/iIdentityService';

@Component({})
export default class PgaRolesSelectUsers extends mixins(SharedMixin)
{
    @Model("", { type: Object }) readonly Model!: SelectUsersPerRoleCommand;

    private isLoading = true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private headers: Array<any> = [
        {
            text: "Id",
            align: " d-none",
            sortable: true,
            value: "Id",
        },
        {
            text: "Full Name",
            align: "start",
            sortable: true,
            value: "FullName",
        },
    ];

    get name() : string
    {
        return RolesModule.RoleDetails?.Name ?? "";
    }

    get listUsers() : Array<UserModel>
    {
        return LookUpsModule.Users;
    }

    get selectedUsers() : Array<UserModel>
    {
        const users = new Array<UserModel>();
        if(RolesModule.SelectUsers.Command)
        {
            RolesModule.SelectUsers.Command.UserIds.forEach(uId =>
            {
                const user: UserModel =
                {
                    Id: uId,
                    FullName: "",
                    Email: "",
                    CreateDate: new Date(),
                    Tenant: 
                    {
                        Id: "",
                        Name: "",
                    },
                    Roles: new Array<RoleModel>(),
                    SIPCode: -1,
                }
                users.push(user);
            });
        }
        return users;
    }
    set selectedUsers(value: Array<UserModel>)
    {
        RolesModule.SET_ROLE_SELECT_USERS_COMMAND_USERIDS(value.map(u => u.Id));
    }

    async created() : Promise<void>
    {
        const id = this.$route.params.id;
        this.isLoading = true;
        await LookUpsModule.GetListUsers();
        await RolesModule.GetRoleToSelectUser(Guid.parse(id));
        this.isLoading = false;
    }
}
