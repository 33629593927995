






























import { Component } from "vue-property-decorator";
import { Guid } from "guid-typescript";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { EditRoleResponse } from "@/services/iIdentityService";
import { EditRoleModel, RolesModule } from "./store";
import PgaRoleEdit from "./Components/PgaRoleEdit.vue";

@Component({
  components: {
    PgaRoleEdit
  },
})
export default class PgaRolesPageEdit extends mixins(SharedMixin)
{  
  private showDialog = false;
	private title = "";
	private message = "";  

  get name(): string
  {
    return this.RoleEdit.ViewModel?.Name ?? "";
  }
  get RoleEdit(): EditRoleModel
  {
    return RolesModule.RoleEdit;
  }

  async Edit() : Promise<void>
	{
		try
		{
			await RolesModule.EditRole();
			this.$router.go(-1);
		}
		// eslint-disable-next-line no-empty
		catch(error) { }
  }
  
  ShowDialogDelete(): void
	{
		this.title = "Deleting Role";
		this.message = `Are you sure you want to delete the Role '${this.RoleEdit.ViewModel?.Name}'?`;
		this.showDialog = true;
	}

	HideDialog(): void
	{
		this.showDialog = false;
		this.title = "";
		this.message = "";
	}

  async Delete(): Promise<void>
	{
		if (this.RoleEdit.ViewModel)
			await RolesModule.DeleteRole(this.RoleEdit.ViewModel.Id);

		this.showDialog = false;
		this.title = "";
		this.message = "";
		this.$router.push({ path: '/roles' });
	}

  cancel(): void {
    this.$router.go(-1);
  }

  async mounted(): Promise<void>
  {
    const id = this.$route.params.id;
    await RolesModule.GetRoleToEdit(Guid.parse(id));
    await RolesModule.GetPermissionsDropDown();
  }

  beforeDestroy() : void
	{
		RolesModule.RoleEdit.Errors = new Map<string, Array<string>>();
	}
}
