













import { Component } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { RoleModel } from '@/services/iIdentityService';
import { RolesModule } from "./store";

import PgaRolesTableIndex from "./Components/PgaRolesTableIndex.vue";
@Component({
  components: {
    PgaRolesTableIndex
  },
})
export default class PgaRolesPageIndex extends mixins(SharedMixin)
{ 
  private isLoading = true;

  get RolesList(): Array<RoleModel> {
    return RolesModule.RoleTable.Roles;
  }

  async mounted(): Promise<void> {
    this.isLoading = true;
    await RolesModule.GetAllRoles();
    this.isLoading = false;
  }
}
